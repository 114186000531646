import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-sass-one-banner',
	templateUrl: './sass-one-banner.component.html',
	styleUrls: ['./sass-one-banner.component.scss']
})
export class SassOneBannerComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

}