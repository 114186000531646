<div class="trusted-company">
    <div class="container">
        <div class="section-title text-center">
            <span>Trusted Companies since 2015</span>
            <h2>Trusted by known brands</h2>
        </div>
        <div class="company-slider">
            <owl-carousel-o [options]="companySlides">
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/sass-landing/company/1.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/sass-landing/company/2.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/sass-landing/company/3.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/sass-landing/company/4.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/sass-landing/company/5.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/sass-landing/company/6.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/sass-landing/company/7.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/sass-landing/company/8.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/sass-landing/company/9.png" alt="company logo">
                    </a>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>