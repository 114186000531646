<div class="standard-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="standard-text">
                    <div class="section-title text-start">
                        <span>About Us</span>
                        <h2>ABYAN MGT. Services Corp</h2>
                    </div>
                    <p>Founded on November 2015, ABYAN MGT. SERVICES CORP. (ABYAN) set out itself to be a service provider company that focuses on bridging good relationship between labor and capital. ABYAN is a corporation duly registered with the Securities and Exchange Commission (SEC), and is engaged in the business of service contracting, operating as a legitimate service contractor under Department Order No. 18-A (now D.O. No. 174) of the Department of Labor and Employment (DOLE).</p>
                    <p>As we grow, working development in ABYAN will be a continuous innovation and will address the growing demand of human resources. Nonetheless, the dignity of work and the right of workers will remain our utmost priority.</p>
                    <p>We are located at 3rd Floor, Unit 18, Legazpi Tower, 2600 Roxas Blvd, Malate, Manila, 1004 Metro Manila</p>
                    <div class="standard-btn">
                        <a routerLink="tel:0287316384">Call us at (02) 8731 6384 </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="standard-img">
                    <img src="assets/img/sass-landing/service/about-us.png" alt="laptop">
                </div>
            </div>
        </div>
        <div class="standard-shape">
            <img src="assets/img/sass-landing/service/about-us-2.png" alt="shape">
        </div>
    </div>
</div>