import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  track(trackingReferenceNumber: string) {
    if (trackingReferenceNumber) {
      const url = `https://delivery.abyan.ph/track/${trackingReferenceNumber}`;
      window.open(url, '_blank');
    }
  }

}
