<div id="pricing" class="pricing-section pricing-style-three pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plan</span>
            <h2>Get Your Affortable Pricing Plan</h2>
            <p>We have the perfect plans for your given budgets. That’s why we brought you three kinds of price planning.</p>
        </div>  
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="price-heading">
                        <h3>Personal Pack</h3>
                        <h2>$12 <span>/Month</span></h2>
                    </div>
                    <div class="price-feature">
                        <ul>
                            <li><i class="flaticon-checked"></i> Limited Trips</li>
                            <li><i class="flaticon-checked"></i> Available Trip History</li>
                            <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                            <li><i class="flaticon-checked"></i> Special Discount</li>
                            <li><i class="flaticon-block"></i> No Free Trip</li>
                            <li><i class="flaticon-block"></i> Membership Card</li>
                            <li><i class="flaticon-block"></i> Trip Cancellation</li>
                            <li><i class="flaticon-block"></i> Money Return</li>
                        </ul>
                    </div>
                    <div class="price-btn">
                        <a routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card active business-class">
                    <div class="price-heading">
                        <h3>Business Class</h3>
                        <h2>$35 <span>/Month</span></h2>
                    </div>
                    <div class="price-feature">
                        <ul>
                            <li><i class="flaticon-checked"></i> Limited Trips</li>
                            <li><i class="flaticon-checked"></i> Available Trip History</li>
                            <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                            <li><i class="flaticon-checked"></i> Special Discount</li>
                            <li><i class="flaticon-checked"></i> No Free Trip</li>
                            <li><i class="flaticon-checked"></i> Membership Card</li>
                            <li><i class="flaticon-block"></i> Trip Cancellation</li>
                            <li><i class="flaticon-block"></i> Money Return</li>
                        </ul>
                    </div>
                    <div class="price-btn">
                        <a routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card premium">
                    <div class="price-heading">
                        <h3>Permium</h3>
                        <h2>$50 <span>/Month</span></h2>
                    </div>
                    <div class="price-feature">
                        <ul>
                            <li><i class="flaticon-checked"></i> Limited Trips</li>
                            <li><i class="flaticon-checked"></i> Available Trip History</li>
                            <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                            <li><i class="flaticon-checked"></i> Special Discount</li>
                            <li><i class="flaticon-checked"></i> No Free Trip</li>
                            <li><i class="flaticon-checked"></i> Membership Card</li>
                            <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                            <li><i class="flaticon-checked"></i> Money Return</li>
                        </ul>
                    </div>
                    <div class="price-btn">
                        <a routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>