<div id="about" class="why-choose why-choose-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>About ABYAN MGT Services Services Corp</span>
            <h2>We Providing Best Qualities</h2>
            <p>We always provide our best qualities ever. Our best qualities are a clean user interface, subheading, easy to use.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/service/best-quality.png" alt="why choose image">
                </div>
            </div>
            <div class="col-lg-5">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="1s">
                    <div class="media d-flex">
                        <!-- <i class="flaticon-computer mr-3"></i> -->
                         <img src="assets/img/sass-landing/service/Mission.png" style="margin-bottom: 36px;">
                        <div class="media-body">
                            <h3 class="mt-0">Mission</h3>
                            <p>To develop professional growth of customer service for the stability and progress of the community. To provide labor force to become competent in handling different level of assigned tasks.</p>
                            
                        </div>
                    </div>
                    <div class="media d-flex">
                        <!-- <i class="flaticon-layers mr-3"></i> -->
                        <img src="assets/img/sass-landing/service/Vision.png" style="margin-bottom: 68px;">
                        <div class="media-body">
                            <h3 class="mt-0">Vision</h3>
                            <p>An equitable employment, progress excellence and innovation of service nurturing life. Purpose-driven employees, working towards the goal of self growth beyond our expectations. A community of self-made workers equipped with the basic modern standard of living</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <!-- <i class="flaticon-countdown mr-3"></i> -->
                        <img src="assets/img/sass-landing/service/Goal.png" style="margin-bottom: 90px;">
                        <div class="media-body">
                            <h3 class="mt-0">Goal</h3>
                            <p>To provide equitable employment, promote progressive excellence and innovation of service nurturing life
                                Local and International Expansion
                                To build a good reputation in the industry
                                Increase opportunity of Driven leadership
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shapes">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
            <img src="assets/img/shape/7.png" alt="shape">
        </div>
    </div>
</div>