<div id="services" class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Servicessss</span>
            <h2>Comprehensive Workforce Solutions</h2>
            <p>Our services are rooted in fostering strong relationships, promoting career growth, and ensuring that both job seekers and businesses thrive in a collaborative and conflict-free environment.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-one wow fadeInUp" data-wow-duration="1s">
                    <!-- <i class="flaticon-pie-chart"></i> -->
                    <img src="assets/img/sass-landing/service/Management.png" style="margin-bottom: 32px;">
                    <h3>Management</h3>
                    <p>Empowering customer service excellence and developing a skilled, competent labor force for community stability and progress.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-two wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                    <!-- <i class="flaticon-rocket"></i> -->
                    <img src="assets/img/sass-landing/service/Construction.png" style="margin-bottom: 32px;">
                    <h3>Construction</h3>
                    <p>Providing equitable employment, fostering innovation, and building industry reputation while expanding locally and globally.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-three wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                    <!-- <i class="flaticon-folder"></i> -->
                    <img src="assets/img/sass-landing/service/Training-center.png" style="margin-bottom: 32px;">
                    <h3>Training Center</h3>
                    <p>Fostering professional growth in customer service and empowering a skilled workforce for community stability and progress.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-four wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                    <!-- <i class="flaticon-relationship"></i> -->
                    <img src="assets/img/sass-landing/service/Express-delivery.png" style="margin-bottom: 32px;">
                    <h3>Express Delivery</h3>
                    <p>Promoting equitable employment, driving excellence, and fostering innovation for global growth and industry reputation.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-five wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                    <!-- <i class="flaticon-cloud-computing"></i> -->
                    <img src="assets/img/sass-landing/service/e-karga-logistics.png" style="margin-bottom: 32px;">
                    <h3>e- Karga Logistics</h3>
                    <p>Developing customer service excellence and empowering a skilled workforce to enhance community stability and progress.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-six wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <!-- <i class="flaticon-verified"></i> -->
                    <img src="assets/img/sass-landing/service/AMR-beverage-trading.png" style="margin-bottom: 32px;">
                    <h3>AMR Beverage Trading</h3>
                    <p>Promoting equitable employment, innovation, and global expansion to build AMR Beverage Trading's industry reputation.</p>
                </div>
            </div>
        </div>
    </div>
</div>