<div class="counter-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-9 offset-lg-1">
                <div class="row">
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-value"></i>
                            <h2><span class="counter">31</span></h2>
                            <p>Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-rating"></i>
                            <h2><span class="counter">782</span>+</h2>
                            <p>Manpower</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-clipboard"></i>
                            <h2><span class="counter">5</span>k+</h2>
                            <p>Vacancies</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-rocket"></i>
                            <h2><span class="counter">9</span>+</h2>
                            <p>Years</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="counter-img wow" data-wow-duration="1s">
        <img src="assets/img/sass-landing/laptop.png" alt="laptop">
        <img src="assets/img/sass-landing/service/phone.png" alt="laptop">
    </div>
</div>