<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </div>
                    <p>Bridges Gaps Between Workforce and Management, Building Strong Foundations for Career Growth and Mutual Success.</p>
                    <div class="footer-link">
                        <!-- <a routerLink="/">
                            <img src="assets/img/google-play.png" alt="google image">
                        </a>
                        <a routerLink="/">
                            <img src="assets/img/app-store.png" alt="google image">
                        </a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Services</h3>
                    <ul>
                        <li><a href="#"><i class="flaticon-right"></i> Management</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Construction</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Training Center</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Express Delivery</a> </li>
                        <li><a href="#"><i class="flaticon-right"></i> E-Karga Logistics</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> AMR Beverage Trading</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Get in touch</h3>
                    <!-- <ul>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Home</span></li>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Features</span></li>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Screenshots</span></li>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Pricing</span></li>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Testimonial</span></li>
                    </ul> -->
                    <p>Office: 3rd Floor, Unit 18, Legazpi Tower, 2600 Roxas Blvd, Malate, Manila, 1004 Metro Manila</p>
                    <p>Contact Number:<br>(02) 8731 6384 </p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Google Map</h3>
                    <!-- <ul>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Facebook</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Twitter</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Linkedin</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Instagram</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Pinterest</a> </li>
                    </ul> -->
                    
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15446.70127081225!2d120.990011!3d14.5605505!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c9dc52f46ed9%3A0x77a8b5dfbc440364!2sABYAN%20MGT.%20SERVICES%20CORP!5e0!3m2!1sen!2sph!4v1723713839469!5m2!1sen!2sph" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-4 lh-1">
                    <!-- <img src="assets/img/visa.png" alt="visa card">
                    <img src="assets/img/american-express.png" alt="american-express">
                    <img src="assets/img/discover.png" alt="discover">
                    <img src="assets/img/master-card.png" alt="master card"> -->
                </div>
                <div class="col-lg-6 col-md-8 lh-1">
                    <p>Copyright 2024 © ABYAN MGT. Service Corp.</p>
                </div>
            </div>
        </div>
    </div>
</footer>