<div class="newsletter">
    <div class="container">
        <div class="newsletter-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-text">
                        <h3>Track and Trace</h3>
                        <p>Track your order</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="newsletter-form">
                        <input type="text" class="form-control" placeholder="enter your tracking ID" name="trackingReferenceNumber" required autocomplete="off" #trackingRef>
                        <button class="default-btn electronics-btn" (click)="track(trackingRef.value)">Track</button>
                    </div>
                </div>
            </div>
            <div class="subscribe-shape">
                <img src="assets/img/shape/subscribe-shape.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-two.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-three.png" alt="shape">
            </div>
        </div>
    </div>
</div>
