<div id="apps" class="our-apps pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="our-apps-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/service/phone.png" alt="iphone">
                </div>
            </div>
            <div class="col-lg-7">
                <div class="our-apps-text">
                    <div class="section-title text-start">
                        <span>ABYAN EXPRESS DELIVERY</span>
                        <h2>Also Available on Your Phone</h2>
                    </div>
                    <p>If you are on the phone, you have a phone in your home or place of work, so that you can be used  by phone. ABYAN is also available on your phone.  Because of these, our website is looking stunning and on all types of screens and devices.</p>
                    <div class="our-apps-btn">
                        <a routerLink="/" class="active">
                            <i class="flaticon-playstore"></i>
                            <p>Get It on</p>
                            <h5>Google Play</h5>
                        </a>
                        <a routerLink="/">
                            <i class="flaticon-app-store"></i>
                            <p>Available on the</p>
                            <h5>App Store</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="apps-shape">
        <img src="assets/img/shape/1.png" alt="shape">
        <img src="assets/img/shape/3.png" alt="shape">
        <img src="assets/img/shape/5.png" alt="shape">
        <img src="assets/img/shape/6.png" alt="shape">
        <img src="assets/img/map-two.png" alt="shape">
    </div>
</div>